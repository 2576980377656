<template>
  <div class="MergedIndexViewContainer">
    <nut-row class="bothCenter pd12 box">
      <span class="ft24 boldFont">我的乘客列表</span>
    </nut-row>

    <nut-tabs v-model="tabName" auto-height>
      <nut-tabpane :title="`未点名列表(${passengers.filter(s=>s.state == 'INIT').length})`"
                   :pane-key="0">
        <template v-for="(p,pi) in passengers" :key="`p_${p.id}`">
          <div class="passenger colFlex pd12 box brd10 mb16" v-if="p.state == 'INIT'">
            <span>序号：{{ pi + 1 }}</span>
            <span>姓名：{{ p.name }}</span>
            <span>班次：{{ p.busName }}</span>
            <span>车次：{{ p.busIndex }}</span>
            <span>乘车日期：{{ p.busDate }}</span>
            <span>乘车时间：{{ p.busTime }}</span>
            <span>票数：{{ p.ticketCnt }}</span>
            <span>上车地址：{{ p.getOnAddress }}</span>
            <span>下车地址：{{ p.getOffAddress }}</span>
            <span style="color: #3D92FD">呼叫次数：{{ p.calls.length }}</span>
            <span style="color: #3D92FD">是否呼通：{{ p.calls.filter(c => c.talked).length > 0 ? '✅' : '-' }}</span>
            <nut-row class="bothCenter mt16">
              <nut-col :span="12" class="bothCenter">
                <nut-button @click="proxyCall(p)" type="info" style="width: 80%">呼叫</nut-button>
              </nut-col>
              <nut-col :span="12" class="bothCenter">
                <nut-button type="success" style="width: 80%" @click="clockIn(p)">签到</nut-button>
              </nut-col>
            </nut-row>
          </div>
        </template>
        <div class="bothCenter lightText ft12">
          <span>没有更多数据了</span>
        </div>
      </nut-tabpane>
      <nut-tabpane :title="`已点名列表(${passengers.filter(s=>s.state != 'INIT').length})`" :pane-key="1">
        <template v-for="(p,pi) in passengers" :key="`p_${p.id}`">
          <div class="passenger colFlex pd12 box brd10 mb16" v-if="p.state != 'INIT'">
            <span>序号：{{ pi + 1 }}</span>
            <span>姓名：{{ p.name }}</span>
            <span>班次：{{ p.busName }}</span>
            <span>车次：{{ p.busIndex }}</span>
            <span>乘车日期：{{ p.busDate }}</span>
            <span>乘车时间：{{ p.busTime }}</span>
            <span>票数：{{ p.ticketCnt }}</span>
            <span>上车地址：{{ p.getOnAddress }}</span>
            <span>下车地址：{{ p.getOffAddress }}</span>
            <span style="color: #3D92FD">呼叫次数：{{ p.calls.length }}</span>
            <span style="color: #3D92FD">是否呼通：{{ p.calls.filter(c => c.talked).length > 0 ? '✅' : '-' }}</span>
            <nut-row class="rightCenter mt16">
              <nut-button @click="proxyCall(p)" type="info" style="width: 40%">呼叫</nut-button>
            </nut-row>
          </div>
        </template>
        <div class="bothCenter lightText ft12">
          <span>没有更多数据了</span>
        </div>
      </nut-tabpane>
    </nut-tabs>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "MergedIndexView",
  components: {},
  data() {
    return {
      tabName:0,
      passengers: [],
      bus: {},
      timer: null,
    }
  },
  methods: {
    clockIn(p) {
      api.mergeClockInPassenger(p.id)
          .then(() => {
            this.init()
          })
    },
    proxyCall(p) {
      api.proxyCallMergedPassenger(p.id)
          .then(ret => {
            window.location.href = 'tel:' + ret;
          })
    },
    init() {
      api.getMergedBusPassengers()
          .then(ret => {
            this.passengers = ret;
          })
    }
  },
  mounted() {
    this.timer = setInterval(() => this.init(), 10000)
    this.init()
  },
  unmounted() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.MergedIndexViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;

  .phone {
    color: #3D92FD;
    text-decoration: none;
  }

  .passenger {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  }
}
</style>